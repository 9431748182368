<template>
  <div class="flex flex-col gap2">
    <v-card
      v-for="item in _items"
      :key="item.key"
      @click="select(item.item)"
      :disabled="item.disabled"
      variant="flat"
      rounded="lg"
    >
      <div class="relative">
        <div class="flex-align gap-2 pl-2 pr-4 py-2 rounded-lg z-2">
          <v-avatar
            :class="{ 'opacity-70': item.active, 'opacity-60': !item.active }"
            rounded="lg"
            :color="item.color"
          >
            <Icon :name="item.item.icon" size="30" />
          </v-avatar>
          <div>
            <div class="heading-2">
              {{ item.item.title }}
              <slot name="append-title" v-bind="{ item: item.item }" />
            </div>
            <div class="caption">{{ item.item.description }}</div>
          </div>
          <v-spacer />
          <v-icon :color="item.color">
            {{
              item.active ? "radio_button_checked" : "radio_button_unchecked"
            }}
          </v-icon>
        </div>
        <v-sheet class="absolute inset-0 z-0 opacity-10" :color="item.color" />
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";

type Item = {
  title: string;
  value: any;
  icon: string;
  description: string;
  disabled?: boolean;
};

export default defineComponent({
  name: "ItemTypeSelect",
  inheritAttrs: false,
  props: {
    modelValue: {},
    items: { type: Array as PropType<Item[]>, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const model = useVModel(props, "modelValue");

    const _items = computed(() =>
      props.items.map((item) => ({
        item,
        key: String(item.value),
        active: item.value == model.value,
        color: item.value == model.value ? "primary" : "grey",
        disabled: props.disabled || item.disabled,
      }))
    );

    const select = (item: Item) => {
      model.value = item.value;
    };

    return {
      _items,
      select,
    };
  },
});
</script>
