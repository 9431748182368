<template>
  <OrderForm :order-form-types="orderFormTypes" resource="event" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { OrderFormType } from "@/layers/admin/models";
import OrderForm from "../../shared/order-form/OrderForm.vue";

export default defineComponent({
  components: { OrderForm },
  data: () => ({
    orderFormTypes: [
      {
        title: "Ticket buyer",
        recommended: true,
        value: OrderFormType.BUYER,
        icon: "heroicons:calendar",
        description:
          "Collect only the ticket buyer's info to speed up checkout and boost sales.",
      },
      {
        title: "Each attendee",
        value: OrderFormType.QUANTITY,
        icon: "heroicons:rectangle-stack",
        description:
          "Collect info for each ticket but require only essential responses.",
      },
    ],
  }),
});
</script>
