<template>
  <GroupMetrics :items="items" metrics-key="event" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const route = useRoute();

    const items = computed(() => {
      const eventScope = {
        args: {
          event: route.params.handle,
        },
      };

      return defineGroupMetricsItems([
        {
          title: "Total event sales",
          value: "event_orders",
          trend: "event_orders",
          withUnit: true,
          formatPrice: true,
          sources: defineDbMetricsSources({
            event_orders: {
              ...eventScope,
              name: "orders",
            },
          }),
        },
        {
          title: "Total tickets sold",
          value: "tickets",
          sources: defineDbMetricsSources({
            tickets: {
              ...eventScope,
              name: "event_booking_tickets",
              aggregate: "count",
              type: "value",
            },
          }),
        },
        {
          title: "Online page views",
          value: "views",
          sources: defineDbMetricsSources({
            views: {
              ...eventScope,
              name: "page_views",
              type: "value",
            },
          }),
        },
        {
          title: "Online conversion rate",
          value: "conversion_rate",
          sources: defineDbMetricsSources({
            //
          }),
        },
      ]);
    });

    return {
      items,
    };
  },
});
</script>
