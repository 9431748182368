<template>
  <v-card class="pa-4">
    <div class="form-section-heading">Order form</div>

    <template v-if="isNotEmpty">
      <ItemTypeSelect v-model="data[field].type" :items="orderFormTypes">
        <template #append-title="{ item }">
          <v-chip v-if="item.recommended" class="ml-2" density="compact">
            Recommended
          </v-chip>
        </template>
      </ItemTypeSelect>

      <div class="mt-4 form-section-heading-2">Questions</div>
      <OrderFormQuestionsInput v-model="questions" class="mt-3" />

      <div class="mt-4 form-section-heading-2">Configurations</div>
      <v-dynamic-form
        class="mt-4"
        v-model="data[field]"
        v-model:valid="valid"
        :inputs="inputs"
        :readonly="!canEdit"
      />

      <div class="flex justify-end">
        <v-switch
          v-model="data[field].disabled"
          class="mt-4"
          color="red"
          density="comfortable"
          inset
          hide-details
        >
          <template #label>
            <span class="mr-1">Disabled</span>
            <TipInfo
              text="If disabled, customers won't see this form. Use this to temporarily disabled forms without removing them."
            />
          </template>
        </v-switch>
      </div>
    </template>

    <div v-else class="h-full view-border text-center pa-8">
      <div class="mb-4">
        Order Form is not configured for this {{ resource }}.
      </div>
      <v-btn @click="create" class="text-none" elevation="1">
        Add Order Form
      </v-btn>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { CheckBox } from "#components";
import { Rule } from "@/services/Rules";
import OrderFormQuestionsInput from "./OrderFormQuestionsInput.vue";
import { OrderFormType } from "@/layers/admin/models";
import type {
  IOrderForm,
  IOrderFormQuestion,
} from "@/models/interfaces/OrderForm";

const defu: IOrderForm = {
  id: "",
  type: OrderFormType.BUYER,
  disabled: false,
  allow_edits: true,
  questions: [],
};

type OrderFormTypeItem = {
  title: string;
  recommended?: boolean;
  value: OrderFormType;
  icon: string;
  description: string;
};

export default defineComponent({
  components: { OrderFormQuestionsInput },
  props: {
    field: { type: String, default: "order_form" },
    resource: { type: String, default: "resource" },
    orderFormTypes: {
      type: Array as PropType<OrderFormTypeItem[]>,
      required: true,
    },
  },
  setup(props) {
    const { loading, data, canEdit, useValid } = useSaveContext<any>();
    const valid = useValid(props.field);

    const questions = computed({
      set(value: IOrderFormQuestion[]) {
        if (data.value[props.field]) {
          data.value[props.field].questions = value;
        }
      },
      get() {
        return data.value[props.field]?.questions || [];
      },
    });

    const isNotEmpty = computed(() => {
      return !!data.value[props.field]?.type;
    });

    const create = () => {
      if (data.value[props.field]) {
        Object.assign(data.value[props.field], defu);
      } else {
        data.value[props.field] = Object.assign({}, defu);
      }
    };

    return {
      loading,
      data,
      valid,
      canEdit,
      questions,
      isNotEmpty,
      create,
    };
  },
  data: () => ({
    inputs: defineFormInputs({
      time_limit: {
        name: "Time limit",
        rules: Rule.group([Rule.number.min(1), Rule.number.max(99)]),
        type: "number",
        tipInfo:
          "This is the time your attendees will have to fill in the information you want to collect. If you have a long form, we suggest you increase the registration time.",
        props: {
          min: 1,
          max: 99,
          // append: "mins",
          placeholder: "Time in minutes",
        },
      },
      allow_edits: {
        hideName: true,
        component: CheckBox,
        class: "mb-2",
        props: {
          title: "Allow attendees to edit information after registration",
          subtitle:
            "Offer your attendees the ability to edit the contact information after purchase",
        },
      },
      instructions: {
        name: "Special instructions",
        rules: Rule.string.max(5000),
        type: "textarea",
        tipInfo:
          "Provide any instructions your attendees might need to complete the order form. If you wish to add any instructions related to the event, please use the confirmation page to do so.",
        props: {
          counter: 5000,
          rows: 3,
          placeholder: "Add special instructions here",
        },
      },
    }),
  }),
});
</script>
