<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <AgendaFields />
        <FaqsFields
          class="mt-4"
          empty-description="Answer your attendees before they ask. Add Frequently Asked Questions here."
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdvancedFields />
      </v-col>
      <v-col cols="12" md="10">
        <OrderForm />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AgendaFields from "./sections/AgendaFields.vue";
import FaqsFields from "../shared/sections/FaqsFields.vue";
import AdvancedFields from "./sections/AdvancedFields.vue";
import OrderForm from "./sections/OrderForm.vue";

export default defineComponent({
  components: {
    AgendaFields,
    FaqsFields,
    AdvancedFields,
    OrderForm,
  },
});
</script>
