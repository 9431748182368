<template>
  <SectionsContent :id="contentId" item-default-text="Question" horizontal>
    <template #section-content="{ model, valid, remove }">
      <v-dynamic-form
        v-model="model.value"
        v-model:valid="valid.value"
        :inputs="inputs(model.value)"
        class="px-4 mt-4"
      />

      <v-divider />

      <div class="px-4 py-2 flex justify-end">
        <v-btn @click="remove" class="text-none" variant="text">
          Remove question
        </v-btn>
      </div>
    </template>
  </SectionsContent>
</template>

<script lang="ts">
import { CheckBox } from "#components";
import { defineComponent } from "vue";
import { OrderFormInputType } from "~/layers/admin/models";
import type { IOrderFormQuestion } from "~/models/interfaces/OrderForm";
import { Rule } from "~/services/Rules";
import OrderFormOptionsInput from "./OrderFormOptionsInput.vue";

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<IOrderFormQuestion[]> },
  },
  setup(props, ctx) {
    const contentId = "order_form:questions";
    const { getValid } = useSaveContext<any>();
    const valid = getValid(["order_form", "questions"]);

    useSectionContent<IOrderFormQuestion>({
      id: contentId,
      sortable: true,
      addText: "Add question",
      emptyIcon: "heroicons:question-mark-circle",
      emptyDescription: "Add questions for attendees to answer before booking.",
      itemTitle: "question",
      items: {
        set(questions) {
          ctx.emit("update:modelValue", questions);
        },
        get() {
          return props.modelValue || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: (index) => {
          return {
            id: uuid(),
            question: index ? `Question ${index + 1}` : "What's your name",
            optional: false,
            type: OrderFormInputType.TEXT,
            limit_multi_choice: false,
            options: [],
          };
        },
      },
    });

    const inputs = (question: IOrderFormQuestion) => {
      const isOptions = [
        OrderFormInputType.CHOICE,
        OrderFormInputType.MULTI_CHOICE,
        OrderFormInputType.DROPDOWN,
      ].includes(question.type);

      return defineFormInputs({
        type: {
          name: "Type",
          rules: Rule.required(),
          class: "mb-2",
          type: "select",
          props: {
            items: arrayFromEnum(OrderFormInputType),
          },
        },
        question: {
          name: "Question",
          rules: Rule.group([Rule.required(), Rule.title()]),
          type: "text",
        },
        optional: {
          hideName: true,
          component: CheckBox,
          class: "mb-4 mt-2",
          props: {
            title: "Optional",
            subtitle: "This question will appear as optional.",
          },
        },
        limit_multi_choice: {
          hideName: true,
          component: CheckBox,
          class: "mb-4 mt-2",
          hidden: !isOptions,
          props: {
            title: "Limit quantity",
            subtitle: "Limit quantity for each option",
          },
        },
        options: {
          name: "Options",
          rules: Rule.required(),
          class: "mb-2",
          hidden: !isOptions,
          component: OrderFormOptionsInput,
          props: {
            parentKey: contentId,
            limitMultiChoice: question.limit_multi_choice,
            question: question.question,
          },
        },
        additional_terms: {
          name: "Additional terms & conditions",
          rules: Rule.string.max(5000),
          type: "textarea",
          hidden: question.type !== OrderFormInputType.ADDITIONAL_TNC,
          props: {
            counter: 5000,
            rows: 3,
          },
        },
      });
    };

    return {
      contentId,
      inputs,
    };
  },
});
</script>
