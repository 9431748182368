export enum OrderFormType {
  /**
   * Form answers are collected once for the entire checkout.
   * Useful for buyer-wide questions.
   */
  BUYER = "BUYER",

  /**
   * Form answers are collected per checkout line item.
   * Useful when questions are specific to each order item.
   */
  LINE = "LINE",

  /**
   * Form answers are collected for each unit (quantity) of each line.
   * Useful when each unit needs individual answers (e.g. names, dietary needs for event attendee).
   */
  QUANTITY = "QUANTITY",
}

export enum OrderFormInputType {
  TEXT = "TEXT",
  PARAGRAPH = "PARAGRAPH",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  YES_OR_NO = "YES_OR_NO",
  CHOICE = "CHOICE",
  MULTI_CHOICE = "MULTI_CHOICE",
  DROPDOWN = "DROPDOWN",
  URL = "URL",
  ADDITIONAL_TNC = "TERMS",
}
