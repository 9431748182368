<template>
  <SectionsContent :id="contentId" item-default-text="Option">
    <template #section-content="{ model, valid, remove, index }">
      <v-dynamic-form
        v-model="model.value"
        v-model:valid="valid.value"
        :inputs="inputs(index)"
        class="px-4 mt-4"
      />

      <v-divider />

      <div class="px-4 py-2 flex justify-end">
        <v-btn @click="remove" class="text-none" variant="text">
          Remove option
        </v-btn>
      </div>
    </template>
  </SectionsContent>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { IOrderFormQuestionOption } from "~/models/interfaces/OrderForm";
import { Rule } from "~/services/Rules";

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<IOrderFormQuestionOption[]> },
    question: { type: String },
    parentKey: { type: String, required: true },
    limitMultiChoice: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const contentId = `order-form:questions:${props.parentKey}`;
    const { getValid } = useSaveContext<any>();
    const valid = getValid(["order_form", "questions", props.parentKey]);

    useSectionContent<IOrderFormQuestionOption>({
      id: contentId,
      sortable: true,
      addText: "Add question",
      emptyIcon: "heroicons:ellipsis-horizontal-circle",
      emptyDescription:
        "Add options" + (props.question ? ` for "${props.question}".` : "."),
      itemTitle: "value",
      items: {
        set(questions) {
          ctx.emit("update:modelValue", questions);
        },
        get() {
          return props.modelValue || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: () => {
          return {
            value: "",
          };
        },
      },
    });

    const inputs = (index: number) => {
      return defineFormInputs({
        value: {
          hideName: true,
          rules: Rule.group([Rule.required(), Rule.title()]),
          type: "text",
          props: {
            placeholder: `Option ${index + 1}`,
          },
        },
        available_quantity: {
          name: "Available quantity",
          rules: Rule.group([Rule.required(), Rule.number.min(1)]),
          type: "number",
          hidden: !props.limitMultiChoice,
          props: {
            min: 1,
          },
        },
      });
    };

    return {
      contentId,
      inputs,
    };
  },
});
</script>
